import React from 'react'

function Disclaimer() {
  return (
    <p className="is-size-6">
      This radar is an experiment to help executives familiarise themselves with
      important things in the world of digital. Thanks to the{' '}
      <a href="https://www.thoughtworks.com/radar">
        Thoughtworks Technology Radar
      </a>{' '}
      for the inspiration.{' '}
    </p>
  )
}

export default Disclaimer
