import React from 'react'
import { Columns } from 'react-bulma-components'

function RadarCategoryHero() {
  return (
    <>
      <h1 className="title">
        Mobile <span className="has-text-primary">Technology Radar</span>
      </h1>
      <Columns className="is-centered">
        <Columns.Column className="is-8">
          <p className="is-size-4">
            Learn the strategies, practices and technologies to make awesome
            mobile apps for your customers and staff.
          </p>
        </Columns.Column>
      </Columns>
    </>
  )
}

export default RadarCategoryHero
