import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

// Components
import ArrowLink from 'components/link-with-arrow'

function TechCard({ blips }) {
  return (
    <Columns className="is-multiline has-text-left">
      {blips.edges.map(({ node: blip }) => (
        <Columns.Column className="is-4 content" key={blip.id}>
          <div
            className={`card is-radar is-equal-height ${
              blip.category ? blip.category.title.toLowerCase() : ''
            }`}
          >
            <i className={`${blip.fontAwesomeId} fa-10x is-text-black bg`} />
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-left">
                  <h5 className="has-text-white">{blip.category.title}</h5>
                </div>
                <div className="level-right">
                  <span className="tag is-rounded is-white">
                    {blip.radarstatus ? blip.radarstatus.title : ''}
                  </span>
                </div>
              </div>
              <h3 className="is-top-marginless">{blip.title}</h3>

              <div
                className="content is-hidden"
                dangerouslySetInnerHTML={{
                  __html: blip.bodyNode.childMarkdownRemark.excerpt,
                }}
              />
            </div>
            <footer className="card-footer">
              <ArrowLink
                className="has-text-white card-footer-item"
                to={`/mobile-technology-radar/${blip.category.slug}/${blip.slug}`}
              >
                Read more
              </ArrowLink>
            </footer>
          </div>
        </Columns.Column>
      ))}
    </Columns>
  )
}
TechCard.propTypes = {
  blips: PropTypes.object.isRequired,
}
export default TechCard
