/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import TechCard from './components/tech-card'
import RadarCategoryHero from './components/radar-category-hero'
import RadarCategoryNav from './components/radar-nav'
import Disclaimer from './components/disclaimer'

function Radar({ data: { blips, categories } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <Section>
        <Container>
          <Columns className="is-centered">
            <Columns.Column className="is-9 has-text-centered content">
              <RadarCategoryHero />
              <RadarCategoryNav categories={categories} />
              <TechCard blips={blips} />
              <Disclaimer />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

Radar.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Radar
