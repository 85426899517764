/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Radar from 'routes/mobile-technology-radar'

// Meta Component
import Meta from 'components/Meta/Meta'

// Radar Route
export default Radar

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query RadarQuery {
    page: datoCmsPage(slug: { eq: "mobile-technology-radar" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
    blips: allDatoCmsRadarBlip(
      filter: { isFeatured: { eq: true } }
      sort: { fields: [meta___updatedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          meta {
            updatedAt
          }
          bodyNode {
            childMarkdownRemark {
              excerpt
            }
          }
          slug
          isFeatured
          fontAwesomeId
          radarstatus {
            title
          }
          category {
            title
            slug
          }
        }
      }
    }
    categories: allDatoCmsRadarCategory(
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          title
          slug
          position
        }
      }
    }
  }
`
