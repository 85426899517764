import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

function RadarNav({ categories }) {
  return (
    <>
      <div className="tabs is-toggle is-toggle-rounded is-medium is-centered">
        <ul>
          <li className="is-active">
            <Link to="/mobile-technology-radar">Spotlight</Link>
          </li>
          {categories.edges.map(({ node: category }) => (
            <li key={category.id}>
              <Link
                to={`/mobile-technology-radar/${category.slug}`}
                activeClassName="is-active"
                partiallyActive
              >
                {category.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <h3>Our picks for the month.</h3>
      <p>
        Summary: Contactless is now the preferred way to do everything,
        including payments. Customer behaviours and needs have changed, so now
        is the perfect time to explore new unmet customer needs. With increased
        remote working, it's a good time to look at employee experiences and how
        information is shared.
      </p>
      <br />
    </>
  )
}
RadarNav.propTypes = {
  categories: PropTypes.object,
}
export default RadarNav
